import React from 'react';
import Home from "./components/home/index";

function App() {
  return <Home />;
  
}

export default App;

