export const WorkData=[
    {
        company:"KOC University",
        designation:"Data Analyst",
        dateJoining:"6 June 2021",
        dateEnd:"28 July 2021",
        companyLogo:require("../../assets/Koç_University_logo.svg").default,
        work: "Helped developing Gaussian packages under prof. Carlson",
    },
    
];